import { Navbar, Home, Servizi, Gallery, Contatti, Footer, Images, Rules, About, } from './Pages'
import { Alert, Whatsapp } from './components'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

function App() {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={
            <>
              <Navbar />
              <Images />
              <Whatsapp />
              <Contatti />
              <Footer />
            </>
          } 
          />
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;
