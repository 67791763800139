import React from 'react'
import { Wrapper } from '../hoc'

const Images = () => {
    return (
        <div className='text-center gap-4 flex flex-col items-center mt-[-30px] '>
            <img src='/orario.jpg' alt='orario' className='rounded-2xl' />
            <img src='/servizi.jpg' alt='orario' className='rounded-2xl' />
            <img src='/servizi2.jpg' alt='orario' className='rounded-2xl' />       
            <img src='/tariffe.jpg' alt='orario' className='rounded-2xl' />     
        </div>
    )
}

export default Wrapper(Images, 'images')